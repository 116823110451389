body
{
    margin: 0;
    background-color: #282a36;
}
.xterm-viewport
{
    width: auto !important;
}
.xterm-viewport::-webkit-scrollbar
{
    width: 10px;
}
.xterm-viewport::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-color: #ffffff20;
}
.xterm-viewport::-webkit-scrollbar-track {
    opacity: 0;
}
::-webkit-scrollbar
{
    width: 10px;
}
::-webkit-scrollbar-thumb
{
    background-color: #282a36;
}
.xterm .xterm-viewport {
    width: initial !important;
}